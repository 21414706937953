import RequestDefinition from "../classes/RequestDefinition";
import RequestGroupDefinition from "../classes/RequestGroupDefinition";

export const getTokens = new RequestDefinition({
  path: "api/tokens",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.tokens ?? null;
  },
});

export const getCsrfCookie = new RequestDefinition({
  path: "sanctum/csrf-cookie",
  method: "get",
});

export const login = new RequestGroupDefinition({
  definitions: [
    new RequestDefinition({
      path: "sanctum/csrf-cookie",
      method: "get",
    }),
    new RequestDefinition({
      path: "login",
      method: "post",
    }),
  ],
});

export const loginToken = new RequestGroupDefinition({
  definitions: [
    new RequestDefinition({
      path: "sanctum/csrf-cookie",
      method: "get",
    }),
    new RequestDefinition({
      path: "login-token",
      method: "post",
    }),
  ],
});

export const logout = new RequestGroupDefinition({
  definitions: [
    new RequestDefinition({
      path: "sanctum/csrf-cookie",
      method: "get",
    }),
    new RequestDefinition({
      path: "logout",
      method: "post",
    }),
  ],
});

export const user = new RequestDefinition({
  path: "user",
  method: "get",
  transformResponse: (response) => {
    return response.data?.content?.user ?? null;
  },
});

export const userAuthToken = new RequestDefinition({
  path: "auth-token",
  method: "post",
  transformResponse: (response) => {
    return response.data?.content ?? null;
  },
});

export const galleryGet = new RequestDefinition({
  path: "gallery",
  method: "get",
  transformResponse: (response) => {
    return {
      tokens: response.data?.content?.tokens ?? [],
      current_page: response.data?.content?.current_page,
      total_pages: response.data?.content?.total_pages,
      sort_by: response.data?.content?.sort_by,
      sort_direction: response.data?.content?.sort_direction,
      filters: response.data?.content?.filters,
    };
  },
});

export const galleryGetFilters = new RequestDefinition({
  path: "gallery/filters",
  method: "get",
  transformResponse: (response) => {
    return response?.data?.content;
  },
});
