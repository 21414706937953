<script setup>
import { onMounted } from "vue";
import { setErrorResponseListener } from "./request";
import store from "./store";
import router from "./router";
import ToastService from "./components/ToastService.vue";
import { useRouter, useRoute } from "vue-router";

const logoutUnauthorized = (error) => {
  console.log("response error", { error });
  if (store.getters["auth/isLoggedIn"] && error.response?.status === 403) {
    store.dispatch("logout");
  }
};

onMounted(() => {
  setErrorResponseListener(logoutUnauthorized);
});

const route = useRoute();
</script>

<template>
  <router-view :key="route.fullPath" />

  <!-- <div class="flex flex-col h-full">
    <div class="flex-grow w-full">
    </div>
  </div> -->
  <ToastService />
</template>

<style></style>
